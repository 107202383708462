exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-limit-wplat-na-ikze-tsx": () => import("./../../../src/pages/limit-wplat-na-ikze.tsx" /* webpackChunkName: "component---src-pages-limit-wplat-na-ikze-tsx" */),
  "component---src-pages-opinie-o-ikze-tsx": () => import("./../../../src/pages/opinie-o-ikze.tsx" /* webpackChunkName: "component---src-pages-opinie-o-ikze-tsx" */),
  "component---src-pages-polityka-prywatnosci-tsx": () => import("./../../../src/pages/polityka-prywatnosci.tsx" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-tsx" */),
  "component---src-pages-porownanie-ofert-ikze-tsx": () => import("./../../../src/pages/porownanie-ofert-ikze.tsx" /* webpackChunkName: "component---src-pages-porownanie-ofert-ikze-tsx" */),
  "component---src-pages-regulamin-tsx": () => import("./../../../src/pages/regulamin.tsx" /* webpackChunkName: "component---src-pages-regulamin-tsx" */),
  "component---src-pages-ulga-podatkowa-tsx": () => import("./../../../src/pages/ulga-podatkowa.tsx" /* webpackChunkName: "component---src-pages-ulga-podatkowa-tsx" */),
  "component---src-templates-blog-list-tsx": () => import("./../../../src/templates/blogList.tsx" /* webpackChunkName: "component---src-templates-blog-list-tsx" */),
  "component---src-templates-company-jsx": () => import("./../../../src/templates/company.jsx" /* webpackChunkName: "component---src-templates-company-jsx" */),
  "component---src-templates-post-jsx": () => import("./../../../src/templates/post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */)
}

